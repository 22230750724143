@import url(https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
::selection {
  background-color: gray;
  color: white;
}

body {
  overflow: hidden;
  font-family: 'Epilogue', sans-serif;
}

.App {
  width: 100vw;
  height: 100vh;
}

canvas {
  width: 100%;
  height: 100%;
  background-color: #272442;
}

.planetInfo-visible {
  display: block;
  position: absolute;
  background-color: white;
  padding: 10px;
  max-width: 400px;
}

.planetInfo-visible h1 {
  font-weight: bold;
  margin: 5px 0px;
}

.planetInfo-visible h2 {
  margin: 5px 0px;
}

.planetInfo-visible p {
  color: #474747;
}

.planetInfo-hidden {
  display: none;
}

.bottomPanel {
  z-index: 50;
  position: absolute;
  bottom: 10px;
  width: 300px;
  margin-left: -150px;
  text-align: center;
  left: 50%;
  background-color: white;
  padding: 10px;
}

.bottomPanel h1 {
  font-size: 1rem;
}

.appIcon {
  display: block;
  width: 60px;
  height: 50px;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 50;
}

.appIcon svg {
  width: 60px;
  height: 50px;
  fill: white;
  opacity: 0.3;
  transition: 0.3s;
}

.appIcon svg:hover {
  opacity: 0.6;
}

.appInfo {
  position: absolute;
  top: 10px;
  left: 80px;
  z-index: 50;
  width: 300px;
  background-color: white;
  padding: 15px;
}

.appInfo h1 {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 5px 10px;
}

.appInfo h2 {
  font-size: 0.8rem;
  color: #333333;
}

.appInfo-enter {
  opacity: 0;
  transform: scale(0.9);
}

.appInfo-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.appInfo-enter-done {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.appInfo-exit {
  opacity: 1;
  background-color: white;
}

.appInfo-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
  background-color: white;
}

.appInfo-exit-done {
  display: none;
  transform: scale(0.9);
  background-color: blue;
  transition: 300ms;
}

.planetInfo {
  position: absolute;
  top: 10px;
  left: 80px;
  z-index: 50;
  width: 300px;
  background-color: white;
  padding: 10px;
}

.planetInfo-enter {
  opacity: 0;
  transform: scale(0.9);
}

.planetInfo-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.planetInfo-enter-done {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.planetInfo-exit {
  opacity: 1;
  background-color: white;
}

.planetInfo-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
  background-color: white;
}

.planetInfo-exit-done {
  display: none;
  transform: scale(0.9);
  background-color: blue;
  transition: 300ms;
}
/*# sourceMappingURL=App.css.map */
